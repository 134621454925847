<template>
  <div>
    <b-button
      v-b-modal.modal-create-sc-tracker
      variant="outline-primary"
      @click="initialSetup"
    >
      Create New
    </b-button>

    <!-- select 2 demo -->
    <b-modal
      id="modal-create-sc-tracker"
      title="Create New Tracker"
      no-close-on-backdrop
      centered
      ok-title="submit"
      cancel-variant="outline-secondary"
    >
      <b-card-text>
        <b-overlay
          :show="$store.state.firTrackerStore.isNewFIRLoading"
          rounded="sm"
          variant="transparent"
          opacity="0.5"
          blur="2px"
        >
          <validation-observer ref="matterForm" #default="{ invalid }">
            <b-form @submit.prevent="fetchFIR">
              <!-- Select Court -->
              <validation-provider
                #default="{ errors }"
                name="Court"
                vid="court"
                rules="required"
              >
                <b-form-group label="Select Court *" label-for="court">
                  <v-select
                    v-model="CourtSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="courtOptions"
                    label="label"
                    placeholder="Select Court"
                    @input="onCourtSelect"
                  />
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="State"
                vid="state"
                rules="required"
              >
                <b-form-group label="Select State *" label-for="state">
                  <v-select
                    v-model="StateSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="StateOptions"
                    label="label"
                    placeholder="Select State"
                    @input="onStateSelect"
                  />
                </b-form-group>
              </validation-provider>

              <b-form-group label="Select Bench (Optional)" label-for="bench">
                <v-select
                  v-model="BenchSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="BenchesOptions"
                  label="label"
                  placeholder="Select Bench"
                  @input="onBenchSelect"
                />
              </b-form-group>

              <div class="row">
                <!-- Case Number -->

                <div class="col-6">
                  <validation-provider
                    #default="{ errors }"
                    name="Case Number"
                    vid="case-number"
                    rules="required"
                  >
                    <b-form-group label-for="case-number" label="Case Number *">
                      <b-form-input
                        id="case-number"
                        rules="required"
                        v-model="CaseNumber"
                        placeholder="Enter Case Number"
                        @input="onCaseNumberChange"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>

                <!-- Select Year -->
                <div class="col-6">
                  <validation-provider
                    #default="{ errors }"
                    name="Year"
                    vid="year"
                    rules="required"
                  >
                    <b-form-group label="Select Year *" label-for="year">
                      <v-select
                        v-model="YearSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="YearOption"
                        rules="required"
                        placeholder="Select Year"
                        @input="onYearSelect"
                      />
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>
            </b-form>
          </validation-observer>
          <template #overlay>
            <div class="text-center">
              <b-spinner variant="secondary"></b-spinner>
              <p id="cancel-label">{{ loadingMsg }}</p>
            </div>
          </template>
        </b-overlay>
      </b-card-text>

      <!-- Footer -->
      <template #modal-footer>
        <!-- By Matter Footer -->
        <div class="w-100">
          <div>
            <div>
              <b-button
                variant="primary"
                class="float-right"
                type="submit1"
                :disabled="$store.state.firTrackerStore.isNewFIRLoading"
                @click="fetchFIR"
              >
                Submit
              </b-button>
            </div>
            <div>
              <b-button
                variant="outline-dark"
                class="float-right mr-1 ml-1"
                type="cancel"
                @click="$bvModal.hide('modal-create-sc-tracker')"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BCardText,
  BFormTextarea,
  BFormGroup,
  BTabs,
  BTab,
  BOverlay,
  BFormRadio,
  BSpinner,
  BFormInvalidFeedback,
  BFormDatepicker,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from 'vue'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BCardText,
    BFormInvalidFeedback,
    BFormGroup,
    BTab,
    BTabs,
    vSelect,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner,
    BFormDatepicker,
    BListGroup,
    BListGroupItem
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  data() {
    return {
      // Matter
      Title: null,
      CourtId: '62e2351a353acc0569e3bf72',
      CourtSelected: null,
      courtOptions: null,
      OtherCaseType: null,
      CaseSelected: null,
      HearingDate: null,
      CaseOption: [],
      CaseNumber: null,
      YearSelected: null,
      YearOption: [],
      StateOptions: [],
      StateSelected: null,
      BenchesOptions: [],
      BenchSelected: null,

      // Data
      CurrentMatter: null,
      loadingMsg: '',
      multipleCases: [],

      // validation rules
      required
    }
  },
  props: ['refreshList'],
  methods: {
    getCourts() {
      // ** Court Setup Starts
      this.$store
        .dispatch('sciTrackerStore/fetchCourts')
        .then((response) => {
          console.log('Court Types --> ', response)
          if (response) {
            this.courtOptions = response.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    initialSetup() {
      // Fetching Court
      // const ll = this.$store.state.courtStore.courtsData
      // console.log({ SDS: ll })
      // if (ll) {
      //   const scl = ll.supremeCourts
      //   if(scl.length > 0) {
      //     console.log(scl[0]._id)
      //     this.CourtId = scl[0]._id
      //   }
      // }

      // Fetching Year
      const years = []
      for (var i = new Date().getFullYear(); i >= 1901; i--) {
        years.push(i)
      }
      this.years = years

      this.CourtSelected = null
      this.CaseNumber = null
      this.YearOption = years
      this.YearSelected = null
      this.BenchesOptions = []
      this.StateSelected = null
      this.BenchSelected = null
    },
    clearAllFields() {
      // Matter
      ;(this.Title = null),
        (this.CourtSelected = null),
        (this.OtherCaseType = null),
        (this.CaseSelected = null),
        (this.CaseOption = []),
        (this.CaseNumber = null),
        (this.CurrentMatter = null),
        (this.YearSelected = null),
        (this.StateOptions = []),
        (this.BenchesOptions = []),
        (this.StateSelected = null),
        (this.BenchSelected = null)
    },
    // Matters
    onCourtSelect(event) {
      ;(this.CaseSelected = null),
        (this.CaseOption = []),
        (this.YearSelected = null),
        (this.Title = null),
        (this.StateOptions = []),
        (this.BenchesOptions = []),
        (this.StateSelected = null),
        (this.BenchSelected = null)
      console.log(event)

      this.$store
        .dispatch('sciTrackerStore/fetchStates')
        .then((response) => {
          console.log('Court Types --> ', response)
          if (response) {
            this.StateOptions = response.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // Matters
    onStateSelect(event) {
      ;(this.CaseSelected = null),
        (this.CaseOption = []),
        (this.YearSelected = null),
        (this.Title = null),
        (this.BenchesOptions = []),
        (this.BenchSelected = null)
      console.log(event)
      this.$store
        .dispatch('sciTrackerStore/fetchBenches', {
          state_id: this.StateSelected.value,
          court_id: this.CourtSelected.value
        })
        .then((response) => {
          console.log('Case Types --> ', response)
          if (response) {
            this.BenchesOptions = response.data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onBenchSelect(event) {
      console.log(event)
    },
    onYearSelect(event) {},
    onCaseNumberChange(event) {},
    fetchFIR() {
      this.loadingMsg = 'Please Wait, Connecting to the Court!'
      event.preventDefault()
      this.$refs.matterForm.validate().then((success) => {
        if (success) {
          let data
          data = {
            court_id: this.CourtId,
            tracking_court_id: String(this.CourtSelected.value),
            case_number: String(this.CaseNumber),
            case_year: String(this.YearSelected),
            state_id: String(this.StateSelected.value),
            bench_id: this.BenchSelected !== null ? String(this.BenchSelected.value) : null,
            bench_name: this.BenchSelected !== null ? String(this.BenchSelected.label) : null
          }
          console.log('Data C', data)
          store
            .dispatch('sciTrackerStore/addTracker', {
              ctx: this,
              payload: data
            })
            .then((response) => {
              console.log({ Matter: response })
              if (response != null) {
                if (response.success) {
                  this.refreshList()
                }
              }
            })
        } else {
          console.log('Invalid Fields')
        }
      })
    }
  },
  beforeMount() {
    this.getCourts()
  },
  setup(props, context) {
    const { refFormObserver, getValidationState, resetForm } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
