import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFIRTrackerList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'case_number', label: 'No./Year', sortable: false },
    { key: 'state_name', sortable: false },
    { key: 'created_at', label: 'Created At', sortable: false },
    { key: 'actions', label: 'Action', sortable: false }
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value
      ? refListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchData = (ctx, callback) => {
    let queryParams = {
      perPage: perPage.value,
      page: currentPage.value
    }

    if (searchQuery.value) {
      queryParams['query'] = searchQuery.value
    }

    store
      .dispatch('sciTrackerStore/getTrackers', {
        ctx: this,
        queryParams: queryParams
      })
      .then((response) => {
        callback(response.data)
        totalInvoices.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching fir tracker's list",
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })
  }

  return {
    fetchData,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    refListTable,
    refetchData
  }
}
